import * as React from "react";
import * as UI from "../components/UI";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { setMayor, setAcepto, modalOpen } from "../redux/app/actions";
import { Helmet } from "react-helmet";

const AgeChooser = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	margin-bottom: 30px;
`;

const Boton = styled.button`
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-size: 17px;
	border: 1px solid #B17F22;
	color: #B17F22;
	padding: 17px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease-out;
	${(props) =>
		props.active &&
		css`
			border-color: #ffffff;
			color: #ffffff;
		`}
`;

const UnderlinedButton = styled.button`
	text-decoration: underline;
`;

const AgePage = ({ mayor, acepto, bootLoaded, boot, s, setMayor, setAcepto, modalOpen }) => {
	const modalLegales = () => {
		modalOpen({ title: boot.legales.title, body: boot.legales.body });
	};

	const modalPrivacidad = () => {
		modalOpen({ title: boot.privacidad.title, body: boot.privacidad.body });
	};

	return (
		<UI.Layout graficos="default" background="1">
			{bootLoaded && (
				<>
					<Helmet>
						<title data-string="indexHTMLTitle">{s.indexHTMLTitle}</title>
					</Helmet>
					<UI.Separador />
					<UI.Titulo data-string="indexEresMayor">{s.indexEresMayor}</UI.Titulo>
					<AgeChooser>
						<Boton data-string="indexSi" active={mayor === true} onClick={() => setMayor(true)}>
							{s.indexSi}
						</Boton>
						<Boton data-string="indexNo" active={mayor === false} onClick={() => setMayor(false)}>
							{s.indexNo}
						</Boton>
					</AgeChooser>
					{mayor === false && (
						<>
							<UI.Parrafo data-string="indexSiEresMenor" theme="error" size="small">
								{s.indexSiEresMenor}
							</UI.Parrafo>
							<UI.Spacer size="22px" />
						</>
					)}
					{(mayor === null || mayor) && (
						<UI.Checkbox
							value={acepto}
							onChange={(v) => setAcepto(v)}
							data-string="indexAcepto indexPoliticas indexY indexBases"
							label={
								<>
									{s.indexAcepto}{" "}
									<UnderlinedButton onClick={modalPrivacidad}>{s.indexPoliticas}</UnderlinedButton> {s.indexY}{" "}
									<UnderlinedButton onClick={modalLegales}>{s.indexBases}</UnderlinedButton>.
								</>
							}
						/>
					)}
					<UI.Boton
						mode={mayor && acepto ? "link" : "disabled"}
						to="/userinfo"
						active={mayor && acepto}
						data-string="indexAcceder"
					>
						{s.indexAcceder}
					</UI.Boton>
				</>
			)}
		</UI.Layout>
	);
};

const mapStateToProps = (state) => {
	const { mayor, acepto, bootLoaded, boot } = state.app;
	const { strings: s } = state.app.boot;
	return {
		mayor,
		acepto,
		bootLoaded,
		boot,
		s,
	};
};

const mapDispatchToProps = {
	setMayor,
	setAcepto,
	modalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgePage);
